import React from "react";
// import {
//     Accordion,
//     AccordionItem,
//     AccordionItemHeading,
//     AccordionItemButton,
//     AccordionItemPanel,
// } from 'react-accessible-accordion';
// import { Link } from "gatsby";
// import 'react-accessible-accordion/dist/fancy-example.css';

// import arrowDown from "../../images/arrow-down.png";
// import logoBlk from "../../images/aisme_labs_logo.svg";
// import logo from "../../images/logo.svg";
// import tabImage from "../../images/tab-image.png";

const WorksSection = () => {
    return (
        <section className="worksection">
            <div className="container">
                <h2 className="worksection__title">How does AI-SME work?</h2>
                <div className="row">
                    <div className=" col-md-4 col-sm-12 mb-3">
                        <div className="worksection__card">
                            <h3 className="worksection__card-title">1</h3>
                            <p className="worksection__card-desc">Help companies through your expertise and digitally transform SME landscape </p>
                        </div>
                    </div>
                    <div className=" col-md-4 col-sm-12 mb-3">
                        <div className="worksection__card">

                            <h3 className="worksection__card-title">2</h3>
                            <p className="worksection__card-desc">Get matched with clients looking for your AI expertise </p>
                            <button className="joinus-card__form_submit" type="submit">Join as an AI-SME</button>
                        </div>
                    </div> <div className=" col-md-4 col-sm-12  mb-3">
                        <div className="worksection__card">

                            <h3 className="worksection__card-title">3</h3>
                            <p className="worksection__card-desc">Solve AI challenges with your real intelligence in-the-loop </p>
                        </div>
                    </div>

                </div>
            </div>
        </section >


    );
};

export default WorksSection;
