import React from "react";
import { Link } from "gatsby";

import Next from "../../images/next.png";
import logoBlk from "../../images/aisme_labs_logo.svg";
import hamburger from "../../images/hamburger-menu.png";
import tabImage from "../../images/tab-image.png";

const JoinUsCard = () => {
    return (
        <div className="joinus-card ">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <div className="joinus-card__container ">
                            <h3 className="joinus-card__title">Join today</h3>
                            <form className="joinus-card__form" method="post" action="#">

                                <div className="joinus-card__form_nameWrapper">
                                    <input type="text" placeholder="First Name*" name="name" id="name" />
                                    <input type="text" placeholder="Last Name*" name="name" id="name" />

                                </div>
                                <input type="email" placeholder="Email*" name="email" id="email" />
                                <input type="number" name="phone" placeholder="Phone*" id="phone" />
                                <input type="Link" name="linkedIn" placeholder="LinkedIn Url*" id="linkedIn" />

                                <button className="joinus-card__form_submit" type="submit">Join as an AI-SME</button>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-1 col-sm-12 m-auto">
                        <img src={Next} className="joinus-card__next" alt="" />

                    </div>
                    <div className="col-lg-5 col-sm-12">

                        <div className="joinus-card__container ">
                            <div className="joinus-card__imgWrapper">
                                <img src={logoBlk} alt="logo" />
                                <img src={hamburger} height="40" alt="logo" />
                            </div>
                            <div className="joinus-card__contentWrapper">
                                <img src={tabImage} className="joinus-card__contentWrapper-img" alt="logo" />
                                <div className="joinus-card__contents">
                                    <h5 className="joinus-card__contents-title">Engagement Request</h5>
                                    <p className="joinus-card__contents-desc">A client in NY is requesting your expertise on finding ways to optimize their harvest forecasting needs ...</p>
                                    <span className="joinus-card__contents-subdesc"> Industrial leader in Commodities Exchange</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    );
};

export default JoinUsCard;
