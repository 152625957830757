import * as React from "react";

import JoinUsTitle from "../components/joinus-banner-title/joinus-banner-title";
import JoinUsCard from "../components/joinus-cards/joinus-cards";
import JoinusFeatures from "../components/joinus-features";
import JoinusWorks from "../components/joinus-works";
import Layout from "../components/layout";
import Questions from "../components/questions";
import { SEO } from "../components/seo";

export const Head = () => <SEO />;

const JoinUs = () => {
  return (
    <Layout headerSolid activeTab={2}>
      <JoinUsTitle />
      <JoinUsCard />
      <JoinusWorks />
      <JoinusFeatures />
      <Questions />
    </Layout>
  );
};

export default JoinUs;
