import React from "react";
// import {
//     Accordion,
//     AccordionItem,
//     AccordionItemHeading,
//     AccordionItemButton,
//     AccordionItemPanel,
// } from 'react-accessible-accordion';
// import { Link } from "gatsby";
// import 'react-accessible-accordion/dist/fancy-example.css';

// import arrowDown from "../../images/arrow-down.png";
// import logoBlk from "../../images/aisme_labs_logo.svg";
// import logo from "../../images/logo.svg";
// import tabImage from "../../images/tab-image.png";

const Features = () => {
    return (
        <section className="features">
            <div className="container">
                <h2 className="features__title">AISME Features & Benefits</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                        <div className="features__card">
                            <h3 className="features__card-title">Share your AI-SME Knowledge</h3>
                            <p className="features__card-desc">Help companies through your expertise and digitally transform SME landscape </p>
                        </div> 
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3">                        <div className="features__card">

                            <h3 className="features__card-title">Expand your horizon</h3>
                            <p className="features__card-desc">As a leader, tackle multi-dimensional industry specific AI challenges </p>
                        </div>
                        </div> <div className="col-lg-4 col-md-6 col-sm-12  mb-3">                        <div className="features__card">

                            <h3 className="features__card-title">Contribute to the AI market</h3>
                            <p className="features__card-desc">The World Economic Forum estimates AI will add 12 million jobs by 2025 and the AI economy will represent over $10 trillion in economic output </p>
                        </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12  mb-3">                         <div className="features__card">

                            <h3 className="features__card-title">Future AI Leaders</h3>
                            <p className="features__card-desc">Begin your journey as a future AI leader and define AI practices, roles and responsibilities </p>
                        </div></div>
                        <div className="col-lg-4 col-md-6 col-sm-12  mb-3">                        <div className="features__card">

                            <h3 className="features__card-title">Join SMEOps Team</h3>
                            <p className="features__card-desc">Engage with managed AI solutions and human-in-the-loop operations </p>
                        </div></div>
                        <div className="col-lg-4 col-md-6 col-sm-12  mb-3">                        <div className="features__card">

                            <h3 className="features__card-title">Flexible engagement model</h3>
                            <p className="features__card-desc">Set a billable rate and make yourself available to clients for 1:1 advisory work </p>
                        </div></div>

                    </div>
                </div>
        </section >


    );
};

export default Features;
