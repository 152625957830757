import React from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { Link } from "gatsby";
import 'react-accessible-accordion/dist/fancy-example.css';

import arrowDown from "../../images/arrow-down.png";
import logoBlk from "../../images/aisme_labs_logo.svg";
import logo from "../../images/logo.svg";
import tabImage from "../../images/tab-image.png";

const Questions = () => {
    return (
        <section className="questions mt-5 mb-5">
            <div className="container">
                <h1 className="questions__title">Frequently asked questions</h1>
                <Accordion>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            How will I benefit from being an AISME?                                         
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            Exposure to real-world AI challenges, flexible working model and earn higher than the market rate.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            Am I bound to accept every engagement offer?                                      
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            While we expect you to be available to accept the offer, you have the option to pass in which case offer is extended to the next available match.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem><AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            Can I be an AISME and be available part-time?                                      
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            Yes, working hours are flexible and can be adjusted in consultation with the client.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem><AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            Do I have to pay AISME for being part of the network?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            No, becoming a part of our network is free.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>

        </section>

    );
};

export default Questions;
