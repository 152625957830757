import React from "react";
import { Link } from "gatsby";

import arrowDown from "../../images/arrow-down.png";

const JoinUsTitle = () => {
    return (
        <div className="joinus-title">
            <div className="container">
                <h1 className="joinus-title__title mb-5">
                    Artificial Intelligence platform for 
                    <span>Subject Matter Experts </span>
                    <span className="joinus-title__title_highlight">
                         like yourself
                    </span>
                </h1>
                <p className="joinus-title__description">Join a global network of experts, define your schedule, engage with customers and create impactful AI solutions.</p>
            </div>
        </div>
    );
};

export default JoinUsTitle;
